
import { Component, Prop, Vue } from "vue-property-decorator";

type Task = {
  id?: number;
  status_id: number;
  status?: {
    id: number;
    name: string;
  };
  info: string;
  order: {
    key_deposited?: string;
    description?: string;
  };
};

@Component
export default class TaskInfoComponent extends Vue {
  @Prop(Object) readonly task!: Task;

  expansionPanel = 0;
}
